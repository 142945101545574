<template>
  <div class="main">
      <div class="info-wrapper">
        <div class="info-wrapper-1">
            <h1>Lorem ipsum</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat at architecto dolores minus nemo vero omnis. Odit rem quo distinctio illum assumenda provident dolores unde dignissimos! Eum qui quod non?</p>
        </div>
        <div class="info-wrappe2">
          <img src="@/assets/responsibility/all-joins.svg" alt="Responsibility - All joins" srcset="">
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae dolor nulla, suscipit eligendi veniam quam, vero reiciendis magnam quisquam rerum deleniti. Porro eius veritatis amet, modi deserunt omnis fugiat ab!</p>
        </div>
      </div>
      <div class="img-wrapper">
        <img src="@/assets/responsibility/rodeca-bg.png" alt="Responsibility - rodeca">
      </div>
      <div class="cards">
        <div class="card">
          <img src="@/assets/responsibility/img1.png" alt="Responsibility Image" srcset="">
          <h2>Lorem ipsum</h2>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Earum pariatur suscipit voluptate reiciendis autem animi quidem quod quasi debitis.</p>
        </div>
        <div class="card">
          <img src="@/assets/responsibility/img1.png" alt="Responsibility Imag" srcset="">
          <h2>Lorem ipsum</h2>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Earum pariatur suscipit voluptate reiciendis autem animi quidem quod quasi debitis.</p>
        </div>
        <div class="card">
          <img src="@/assets/responsibility/img1.png" alt="Responsibility Imag" srcset="">
          <h2>Lorem ipsum</h2>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Earum pariatur suscipit voluptate reiciendis autem animi quidem quod quasi debitis.</p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'ItemView'
}
</script>

<style lang="scss" scoped>
  .main{
    width: 90%;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-right: 10%;
  }
  .info-wrapper{
    display: flex;
    width: 100%;
    height: 500px;
    align-items: center;
    justify-content: space-around;
    .info-wrapper-1{
      h1{
        text-align: left;
        text-transform: uppercase;
      }
      p{
        text-align: left;
        max-width: 350px;
      }
    }
    .info-wrappe2{
      width: 500px;
      display: flex;
      flex-direction: column;
      p{
        max-width: 500px;
      }
    }
  }
  .img-wrapper{
    img{
      width: 90vw;
      height: 600px;
    }
  }
  .cards{
    margin: 50px 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    .card{
      width: 400px;
      display: flex;
      flex-direction: column;
      h1{
        margin-top: 20px;
        text-align: left;
        text-transform: uppercase;
      }
      p{
        max-width: 390px;
        text-align: left;
      }
    }
  }
</style>
